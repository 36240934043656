import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading, Modal, UserCard } from '../../components';
import InquiryForm from './InquiryForm/InquiryForm';

import css from './ListingPage.module.css';

const SectionAuthorMaybe = props => {
  const {
    title,
    listing,
    authorDisplayName,
    onContactUser,
    isInquiryModalOpen,
    onCloseInquiryModal,
    sendInquiryError,
    sendInquiryInProgress,
    onSubmitInquiry,
    currentUser,
    onManageDisableScrolling,
    submitGuestPhoneNumber,
    intl,
  } = props;

  if (!listing.author) {
    return null;
  }

  function extractCityStateCountry(address) {
    // Regular expressions for different formats
    const formatStreetCityStateZipCountry = /(.*?),\s*([A-Za-z\s]+),\s*([A-Za-z]+)\s*\d{5},\s*([A-Za-z\s]+)/; // Street, City, State, Zip, Country
    const formatCityStateCountry = /([A-Za-z\s]+),\s*([A-Za-z]+),\s*([A-Za-z\s]+)/; // City, State, Country

    // Check for format 1: "Street, City, State, Zip, Country"
    const match1 = address.match(formatStreetCityStateZipCountry);
    if (match1) {
      const city = match1[2].trim();
      const state = match1[3].trim();
      const country = match1[4].trim();
      return { city, state, country };
    }

    // Check for format 2: "City, State, Country"
    const match2 = address.match(formatCityStateCountry);
    if (match2) {
      const city = match2[1].trim();
      const state = match2[2].trim();
      const country = match2[3].trim();
      return { city, state, country };
    }

    // If the format does not match, return nulls
    return { city: null, state: null, country: null };
  }
  const isShippingOnly =
    listing?.attributes?.publicData?.shippingEnabled &&
    !listing?.attributes?.publicData?.pickupEnabled &&
    !listing?.attributes?.publicData?.lastMileDeliveryEnabled;

  // Extracting information from both addresses
  const listingAddress =
    (listing?.attributes?.publicData?.listinglocation?.address &&
      extractCityStateCountry(listing?.attributes?.publicData?.listinglocation?.address)) ||
    (listing?.attributes?.publicData?.location?.address &&
      extractCityStateCountry(listing?.attributes?.publicData?.location?.address));

  return (
    <div id="author" className={css.sectionAuthor}>
      <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
        <FormattedMessage id="ListingPage.aboutProviderTitle" />
      </Heading>
      <UserCard
        user={listing.author}
        currentUser={currentUser}
        onContactUser={onContactUser}
        listingAddress={listingAddress}
        isShippingOnly={isShippingOnly}
        submitGuestPhoneNumber={submitGuestPhoneNumber}
        intl={intl}
      />
      <Modal
        id="ListingPage.inquiry"
        contentClassName={css.inquiryModalContent}
        isOpen={isInquiryModalOpen}
        onClose={onCloseInquiryModal}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <InquiryForm
          className={css.inquiryForm}
          submitButtonWrapperClassName={css.inquirySubmitButtonWrapper}
          listingTitle={title}
          authorDisplayName={authorDisplayName}
          sendInquiryError={sendInquiryError}
          onSubmit={onSubmitInquiry}
          inProgress={sendInquiryInProgress}
        />
      </Modal>
    </div>
  );
};

export default SectionAuthorMaybe;
